





















































import Vue from 'vue'
import moment from 'moment/moment'
import PhrasesDetailsReportStore from '@/store/searchV3Report/phrasesDetails'
import store from '@/store'

interface ColumnDescription {
  displayName: string,
  name: string,
  class: string,
  unit: string,
}

export default Vue.extend({
  data() {
    return {
      columns: [
        {
          displayName: this.$t('search.searchPhrases.phrase'),
          name: 'phrase',
          class: 'text-left',
        },
        {
          displayName: this.$t('search.searchPhrases.searchCount'),
          name: 'views',
          class: 'text-right',
        },
        {
          displayName: this.$t('search.searchPhrases.clicks'),
          name: 'clicks',
          class: 'text-right',
        },
        {
          displayName: this.$t('search.searchPhrases.ctr'),
          name: 'ctr',
          class: 'text-right',
          unit: '%',
        },
        {
          displayName: this.$t('search.searchPhrases.conversion'),
          name: 'sales_conversion',
          class: 'text-right',
          unit: '%',
        },
        {
          displayName: this.$t('search.searchPhrases.salesCount'),
          name: 'sales_count',
          class: 'text-right',
        },
        {
          displayName: this.$t('search.searchPhrases.salesUnitsSold'),
          name: 'sales_units_sold',
          class: 'text-right',
        },
        {
          displayName: this.$t('search.searchPhrases.salesTotal'),
          name: 'sales_total',
          class: 'text-right whitespace-no-wrap',
          unit: ' zł',
        },
      ] as ColumnDescription[],
      sort: 'desc',
      page: 1,
      orderBy: 'sales_total',
    }
  },
  computed: {
    dataLoading() {
      return PhrasesDetailsReportStore.state.allPhrases.dataLoading
    },
    results() {
      return PhrasesDetailsReportStore.state.allPhrases.data
    },
    totalPages() {
      return PhrasesDetailsReportStore.state.allPhrases.totalPages
    },
    start() {
      return (store.state as any).search.dateRange.start
    },
    end() {
      return (store.state as any).search.dateRange.end
    },
    dataLoadingFile() {
      return PhrasesDetailsReportStore.state.allPhrases.fileDataLoading
    },
  },
  watch: {
    start() {
      PhrasesDetailsReportStore.commit('setAllPhrasesTimeFrame', {start: this.start, end: this.end})
      this.getData()
    },
    end() {
      PhrasesDetailsReportStore.commit('setAllPhrasesTimeFrame', {start: this.start, end: this.end})
      this.getData()
    },
    page() {
      PhrasesDetailsReportStore.commit('setAllPhrasesPage', this.page)
      this.getData()
    },
  },
  mounted() {
    PhrasesDetailsReportStore.commit('setAllPhrasesTimeFrame', {start: this.start, end: this.end})
    this.getData()
  },
  methods: {
    isOrderByThisColumnActive(columnName: string) {
      return this.orderBy === columnName
    },
    getArrowUpOrDown() {
      return this.sort === 'asc' ? 'expand_less' : 'expand_more'
    },
    flipSortDirection() {
      if (this.sort === 'asc') {
        this.sort = 'desc'
      } else {
        this.sort = 'asc'
      }
    },
    setDefaultSortDirectionForColumn(column: ColumnDescription) {
      this.orderBy = column.name
      this.sort = 'desc'
    },
    manageOrder(column: ColumnDescription) {
      if (this.orderBy === column.name) {
        this.flipSortDirection()
      } else {
        this.setDefaultSortDirectionForColumn(column)
      }
      PhrasesDetailsReportStore.commit('setAllPhrasesOrderBy', column.name)
      PhrasesDetailsReportStore.commit('setAllPhrasesSort', this.sort)
      this.getData()
    },
    formatStartDate(date: string) {
      return moment(date).format('YYYY-MM-DD 00:00:00')
    },
    formatEndDate(date: string) {
      return moment(date).format('YYYY-MM-DD 23:59:59')
    },
    getData() {
      PhrasesDetailsReportStore.dispatch('getAllPhrasesData')
    },
    getFileWithData() {
      PhrasesDetailsReportStore.dispatch('getAllPhrasesDataFile')
    },
  },
})
